.App {
  text-align: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.fullpage {
  width: 100%;
  height: 100%;
}
